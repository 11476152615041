import React from 'react'

const SvgIcAtendimentoDomiciliar = props => (
  <svg
    version="1.1"
    id="Camada_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 1080 990.13"
    style={{ width: '28px', height: '28px' }}
  >
    <g>
      <path
        fill="#AF1E2C"
        d="M1053.29,409.02c-0.88-12.58-5.14-24.01-12.73-34.16c-3.82-6.65-11.82-13.96-19.33-19.92
		c0.09-0.15,0.15-0.3,0.22-0.45c-21.64-15.1-42.51-30.4-64.12-44.51c-13.66-8.93-20.35-20.52-20.37-36.64
		c-0.04-21.27-0.26-42.56-0.64-63.83l0.02,0l-0.03-0.86c-0.08-4.67-0.13-9.35-0.23-14.02c-0.02-0.99-0.13-2.01-0.35-3.02
		l-1.19-35.99c0.99-2.89,0.22-6.71,0-11.09c-1.51-30.03-20.11-49.47-49.99-49.78c-27.32-0.26-54.63-0.3-81.97,0.02
		c-29.37,0.35-50.55,21.94-51.07,51.48c-0.09,4.77-0.02,9.54-0.02,16.4c-44.53-31.42-86.41-61.75-129.2-90.73
		C606.4,61.19,589.23,50.72,571.1,45.2c-41.84-12.79-78.69,1.06-113.12,25.89c-96.1,69.28-192.72,137.83-289.12,206.68
		c-38.28,27.34-77.14,53.92-114.55,82.4c-26.34,20.04-33.55,48.5-23.39,77.85c9.9,28.55,33.1,44.27,66.58,45.14
		c30.72,0.78,33.03,3.06,33.01,33.66c-0.02,103.54-0.24,207.07,0.09,310.61c0.04,13.68,1.66,27.62,4.62,40.99
		c9.19,41.86,35.99,66.95,77.39,75.93c22.72,4.92,45.46,6.19,68.14,5.03c4.68,0.04,73.19,0.13,146.83-34.72
		c0.02,0,0.02-0.02,0.04-0.02c36.92-15.73,71.68-35.69,105.06-58.28c5.7-3.86,9.26-3.78,15.02-0.11
		c20.28,12.95,40.67,25.87,61.77,37.35c72.22,39.25,148.96,59.9,231.75,55.02c54.5-3.19,91.07-29.65,103.46-76.92
		c3.58-13.74,4.9-28.37,5.09-42.63c0.56-40.59-0.06-81.17-0.11-121.76c-0.06-64-0.04-127.99,0-191.99c0-5.37,0.17-10.79,0.82-16.12
		c1.08-8.98,6.06-14.43,15.41-15.08c7.88-0.56,15.79-0.69,23.65-1.32C1029.32,479.62,1056.14,448.78,1053.29,409.02z M545.62,788.91
		c-2.44,1.88-8.63,2.09-10.98,0.32c-44.86-34.18-90.25-67.73-133.73-103.59c-37.07-30.57-69.39-66-89.05-110.84
		c-19.68-44.9-22.53-90.92-1.77-135.93c32.39-70.23,115.78-87.64,179.17-38.43c11.35,8.82,21.9,18.62,33.03,27.75
		c11.91,9.75,22.05,10.08,34.2,0.86c12.88-9.77,25.37-20.04,38.15-29.97c32.95-25.55,69.82-34.61,109.5-20.84
		c37.35,12.95,61.23,40.84,72.69,78.39c4.66,15.28,6.04,31.57,8.91,47.4c-2.03,26.22-7.68,50.79-18.62,74.33
		c-22.31,47.96-38.38,63.89-86.05,105.66C638.21,721.62,591.13,754.39,545.62,788.91z M1003.95,423.22
		c-3.15,8.8-10.46,11.28-18.73,12.02c-7.88,0.69-15.82,0.69-23.65,1.62c-34.74,4.08-56.16,25.76-59.01,60.65
		c-1.25,15.38-0.97,30.9-0.99,46.35c-0.04,25.4-0.06,50.77-0.06,76.16c0.02,0.76,0,1.58,0,2.46c-0.04,36.33-0.24,171.98-0.32,221.42
		c-0.15,1.66-0.32,3.32-0.52,4.96c-3.47,29.15-18.45,45.53-47.73,50.08c-39.64,6.17-78.95,2.33-117.72-6.84
		c-53.81-12.75-102.42-37.33-148.66-67.04c-1.14-0.76-2.11-1.79-4.01-3.41c18.3-13.46,36.25-26.52,54.01-39.81
		c42.35-31.67,83.91-64.3,119.73-103.52c34.74-38.04,62.2-80.31,71.5-131.9c11.24-62.18,1.58-120.27-41.86-168.49
		c-57.91-64.34-146.93-71.46-217.1-19.03c-9.19,6.86-18.15,14.09-28.07,21.84c-4.38-3.56-8.74-7.08-13.1-10.62
		c-56.23-45.83-118.13-61.43-185.47-28.7c-56.98,27.7-84.99,76.7-91.83,139.12c-9.02,82.34,25.85,147.13,80.09,204.35
		c46.52,49.06,101.32,88.16,155.59,127.84c3.71,2.72,7.44,5.44,12.54,9.19c-78.32,51.46-160.4,84.69-254.38,79.06
		c-10.62-0.63-21.43-2.96-31.54-6.32c-11.76-3.91-20.5-11.44-26.11-22.05c-6.19-15.32-8.05-48.44-8.46-75.37
		c0.13-1.04,0.22-2.05,0.22-3.09c0.56-75.15,0.32-150.3,0.32-225.47c0-17.87,0.19-35.73-0.09-53.6c-0.15-8.61-0.41-17.35-2.05-25.74
		c-6.58-33.72-29.26-51.57-67.1-53.55c-5.37-0.28-10.83-0.04-16.14-0.78c-17.33-2.35-24.62-20.07-13.51-33.7
		c4.92-6.04,11.22-11.2,17.63-15.73C227.8,293,358.27,200.46,488.94,108.22c38.49-27.14,63.74-26.88,102.7,0.26
		c42.48,29.56,84.43,59.9,127.06,89.26c11.41,7.85,23.86,15.08,36.9,19.55c21.45,7.38,39.53-4.32,42.1-26.88
		c1.73-15.19,0.32-30.77,0.32-46.73c4.6-0.63,7.34-1.34,10.08-1.34c23.39-0.09,46.76,0.15,70.12-0.17
		c7.31-0.11,9.99,2.09,10.23,9.75c0.45,14.84,3.17,29.62,3.09,44.43c-0.09,17.87-3.28,35.75-2.96,53.57
		c0.3,18.23,1.36,36.85,5.65,54.44c2.72,11.07,9.97,22.87,18.66,30.25c19.09,16.25,40.5,29.75,60.78,44.64
		c8.67,6.34,17.26,12.9,25.2,20.13C1005.89,405.78,1007.16,414.22,1003.95,423.22z"
      />
    </g>
  </svg>
)

export default SvgIcAtendimentoDomiciliar
