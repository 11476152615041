import { bodySecondaryFontSize, bodySecondaryLineHeight, Border, Color, Spacing } from 'atomic'
import { textMaxLines } from 'atomic/legacy/obj.mixin'
import ColorFunc from 'color'
import styled, { css } from 'styled-components'
import { CardKind } from './card.component'

export const CardStyled = styled.div`
  background-color: ${Color.White};
  border-radius: ${Border.Radius};
  box-shadow: 0px 4px 2px ${Color.GrayLight};
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  position: relative;
  transition: all 0.3s ease-in-out 0.1s;

  :hover {
    transform: scale(1.01, 1.01);
  }
  // https://tobiasahlin.com/blog/how-to-animate-box-shadow/
  ::after {
    content: '';
    border-radius: 5px;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0px 4px 8px ${Color.Gray};
    opacity: 0;
    transition: all 0.3s ease-in-out 0.1s;
  }
  :hover::after {
    opacity: 1;
  }

  @media all and (max-width: 64em) {
    max-width: 255px;
  }
`

export const ImgWrapperStyled = styled.div<{ kind: CardKind }>`
  ${props => (props.kind !== 'image' ? ImgWrapperDifferentialsStyle : ImgWrapperFullImageStyle)};
  overflow: hidden;
`

const ImgWrapperDifferentialsStyle = css`
  width: 65px;
  height: 65px;
  margin-left: ${Spacing.Large};
  margin-top: ${Spacing.Large};
  border-radius: ${Border.Radius};
  overflow: hidden;
  background-color: ${ColorFunc(Color.Gray)
    .alpha(0.1)
    .hsl()
    .string()};
`
const ImgWrapperFullImageStyle = css`
  border-radius: ${Border.Radius} ${Border.Radius} 0 0;
`

export const CardLinkWrapperStyled = styled.div`
  padding: ${Spacing.Large};
`

export const CardTextboxTitleStyled = styled.div`
  padding: 0 ${Spacing.Large};
`

export const CardTextboxBodyWrapperStyled = styled.div`
  padding: 0 ${Spacing.Large};
  color: ${Color.GrayDark};
  ${textMaxLines(3, bodySecondaryLineHeight * parseInt(bodySecondaryFontSize, 0))}
`
