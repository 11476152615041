import { Row, Spacing } from 'atomic'
import styled from 'styled-components'

export const CardsSectionStyled = styled.div`
  overflow: hidden;
`

export const CardWrapper = styled.div`
  height: calc(100% - ${Spacing.Small});
  padding: ${Spacing.Small} 0;
`
export const CardsRow = styled(Row)`
  overflow: visible;
`
