import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`

export const Image = styled.img`
  margin-top: 15px;
  margin-bottom: 11px;
  width: 100%;
  border-radius: 8px;
`

export const Description = styled.span`
  font-family: Signika;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: #373a3a;
  width: 100%;
  margin-bottom: 10px;
`
