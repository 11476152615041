import * as React from 'react'

import { Wrapper } from './org.cards-section-doctor.style'
import { CardSectionDoctor } from './org.card'
import { Container, Grid } from '@material-ui/core'

export const CardsSectionDoctor = ({ options }: any) => {
  return (
    <Wrapper>
      <Container maxWidth="md">
        <Grid container spacing={4}>
          {options.map(card => (
            <Grid item xs={12} sm={6}>
              <CardSectionDoctor
                title={card.titulo}
                img={card.image_opcao.imgix_url}
                description={card.descricao}
                buttonDescription={card.texto_botao}
                buttonLink={card.link_botao}
              />
            </Grid>
          ))}
        </Grid>
      </Container>
    </Wrapper>
  )
}
