import { H4 } from 'atomic'
import { ForcedFade } from 'atomic/legacy/obj.animation/animation.component.style'
import React from 'react'
import {
  VerticalIconTitleStyled,
  VerticalIconTitleStyledProps
} from './vertical-icon-title.component.style'

interface VerticalIconTitleProps extends VerticalIconTitleStyledProps {
  /** it is expected to receive an svg */
  icon: JSX.Element
  title: string
  kind?: 'light' | 'default'
  light?: boolean
}

export const VerticalIconTitle: React.FunctionComponent<VerticalIconTitleProps> = props => {
  return (
    <ForcedFade show={true}>
      <VerticalIconTitleStyled actionColor={props.actionColor} id={'test-vertical-icon-title'}>
        {props.icon}
        <H4 kind={props.kind} cell={true}>
          {props.title}
        </H4>
      </VerticalIconTitleStyled>
    </ForcedFade>
  )
}

VerticalIconTitle.defaultProps = {
  kind: 'light'
}
