import React from 'react'

const SvgIcFaleConosco = props => (
  <svg viewBox="0 0 32 32" {...props}>
    <defs>
      <path
        d="M25 24V9H7v15h18zm0 2H7v3a1 1 0 001 1h16a1 1 0 001-1v-3zm0-19V3a1 1 0 00-1-1H8a1 1 0 00-1 1v4h18zM8 0h16a3 3 0 013 3v26a3 3 0 01-3 3H8a3 3 0 01-3-3V3a3 3 0 013-3zm1 5.5a1 1 0 110-2h14a1 1 0 010 2H9zM16 29a1 1 0 110-2 1 1 0 010 2z"
        id="ic-fale-conosco_svg__a"
      />
    </defs>
    <g fillRule="evenodd">
      <mask id="ic-fale-conosco_svg__b" fill="#fff">
        <use xlinkHref="#ic-fale-conosco_svg__a" />
      </mask>
      <use fill={props.fill} fillRule="nonzero" xlinkHref="#ic-fale-conosco_svg__a" />
    </g>
  </svg>
)

export default SvgIcFaleConosco
