import styled from 'styled-components'
import { Color, Spacing, Border } from 'atomic/legacy/obj.constants'

export const FrameStyled = styled.div`
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  background-color: ${Color.White};
  border: 1px solid ${Color.GrayLight};
  border-radius: ${Border.Radius};
  position: relative;
  padding: ${Spacing.Small};
  height: 100%;
`
