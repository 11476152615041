import { Button, Col, Grid, Row, Separator } from 'atomic'
import SvgIcFaleConosco from 'atomic/atm.svg-icon/ic-fale-conosco'
import { FrameStyled } from 'atomic/legacy/atm.frame/frame.component.style'
import { BooleanContainer } from 'atomic/legacy/obj.abstract-container'
import * as React from 'react'
import { ThemeContext } from 'styled-components'
import { isNullOrUndefined } from 'util'
import { QuickAccessItem } from 'utils/model/quick-access'
import { isExternalUrl } from 'utils/url'
import { ContactUsModal } from '../mol.contact-us-modal/contact-us-modal.component'
import { QuickAccessStyled } from './quick-access.component.style'
import { VerticalIconTitle } from './vertical-icon-title.component'

export let doctorQuickAccess: QuickAccessItem[]
export let clientQuickAccess: QuickAccessItem[]
if (process.env.GATSBY_MARCA === 'FLEURY') {
  doctorQuickAccess = require('fleury/components/mol.quick-access/quick-access.component')
    .doctorQuickAccess
  clientQuickAccess = require('fleury/components/mol.quick-access/quick-access.component')
    .clientQuickAccess
} else if (process.env.GATSBY_MARCA === 'WEINMANN') {
  doctorQuickAccess = require('weinmann/components/mol.quick-access/quick-access.component')
    .doctorQuickAccess
  clientQuickAccess = require('weinmann/components/mol.quick-access/quick-access.component')
    .clientQuickAccess
} else if (process.env.GATSBY_MARCA === 'FM') {
  doctorQuickAccess = require('felippe-mattoso/components/mol.quick-access/quick-access.component')
    .doctorQuickAccess
  clientQuickAccess = require('felippe-mattoso/components/mol.quick-access/quick-access.component')
    .clientQuickAccess
}

// BUSINESS_RULE: the contact quickAccess will be inserted in this index
const indexToInsertContact = 4

interface QuickAccessProps {
  options: QuickAccessItem[]
  isDoctor: boolean
}

export const QuickAccess: React.FunctionComponent<QuickAccessProps> = props => {
  const themeContext = React.useContext(ThemeContext)

  const options = props.options.slice()
  // insert Contact
  options.splice(indexToInsertContact, 0, null)

  return (
    <QuickAccessStyled>
      <Grid>
        <Row>
          {options.map((item, index) => (
            <>
              {index % 3 === 0 && (
                <Col xs={12}>
                  <Separator />
                </Col>
              )}
              {index === indexToInsertContact && (
                <Col xs={4}>
                  <BooleanContainer>
                    {contactModal => (
                      <>
                        <FrameStyled onClick={contactModal.setTrue}>
                          <VerticalIconTitle
                            kind="default"
                            actionColor={themeContext.color.primary}
                            icon={<SvgIcFaleConosco height={24} />}
                            title="Fale Conosco"
                          />
                        </FrameStyled>
                        <ContactUsModal
                          open={contactModal.value}
                          onClose={contactModal.setFalse}
                          isDoctor={props.isDoctor}
                        />
                      </>
                    )}
                  </BooleanContainer>
                </Col>
              )}
              {!isNullOrUndefined(item) && (
                <Col xs={4}>
                  <FrameStyled>
                    <Button
                      id="button-vertical-title-quick-access"
                      expanded
                      kind="link"
                      to={item.path}
                      aria-label={item.title}
                      external={isExternalUrl(item.path)}
                    >
                      <VerticalIconTitle
                        kind="default"
                        actionColor={themeContext.color.primary}
                        icon={item.icon}
                        title={item.title}
                      />
                    </Button>
                  </FrameStyled>
                </Col>
              )}
            </>
          ))}
        </Row>
      </Grid>
    </QuickAccessStyled>
  )
}
