import { NeutralBackground } from '@root/src/components/atm.background/background.styled'
import { SEO } from '@root/src/components/legacy/mol.seo/seo.component'
import { headerBreakpoint } from '@root/src/components/legacy/org.header/header.component.style'
import { CardKind } from '@root/src/components/mol.card/card.component'
import {
  doctorQuickAccess,
  QuickAccess
} from '@root/src/components/mol.quick-access/quick-access.component'
import { CardsSectionDoctor } from '@root/src/components/org.cards-section-doctor/org.cards-section-doctor'
import { CardsSectionMedicalMagazine } from '@root/src/components/org.cards-section-medical-magazine/org.cards-section-medical-magazine'
import {
  CardData,
  CardsSection
} from '@root/src/components/org.cards-section/cards-section.component'
import { FeaturedCarouselNew } from '@root/src/components/org.featured-carousel-new/featured-carousel-new.component'
import {
  FeaturedCarousel,
  FeaturedCarouselProps
} from '@root/src/components/org.featured-carousel/featured-carousel.component'
import IndexLayout from '@root/src/components/org.layout/layout.component'
import { AccessTokenDataSource } from '@root/src/data/access-token.datasource'
import {
  Query,
  CosmicjsHomeMedico,
  CosmicjsArtigosCientificosConnection
} from '@root/src/data/graphql/graphql-types'
import { PageProps } from '@root/src/utils/local-types'
import { MatchMedia } from 'atomic/legacy/obj.match-media'
import { BannerCarousel } from 'atomic/org.banner-carousel/banner-carousel.component'
import { BannerCarouselNew } from '@root/src/components/org.banner-carousel-new/banner-carousel.component-new'
import { graphql } from 'gatsby'
import React from 'react'
import { appPaths } from 'utils/path'
import { getDetailPageUrl } from 'utils/url'

import { ContainerBannerWeinmann, ContainerCardsWeinmann } from './client-doctor-home-styled.jsx'

let DoctorLastSectionComponent
if (process.env.GATSBY_MARCA === 'FLEURY') {
  DoctorLastSectionComponent = require('fleury/modules/home/org.home-section/doctor-home-differentials-section.component')
    .default
} else {
  DoctorLastSectionComponent = () => null
}

let DoctorMiddleSectionComponent
if (process.env.GATSBY_MARCA === 'FLEURY') {
  DoctorMiddleSectionComponent = require('fleury/modules/home/org.home-section/doctor-home-medical-education-section.component')
    .default
} else {
  DoctorMiddleSectionComponent = () => null
}

export interface DoctorHomeDetailTemplateProps {
  cosmicjsHomeMedico?: CosmicjsHomeMedico
  allCosmicjsArtigosCientificos?: CosmicjsArtigosCientificosConnection
}

class HomeDoctor extends React.Component<PageProps<DoctorHomeDetailTemplateProps, Query>> {
  render() {
    const accessTokenDataSource: AccessTokenDataSource = new AccessTokenDataSource(
      process.env.GATSBY_FLEURY_BASE_URL,
      process.env.GATSBY_FLEURY_CLIENT_ID_SENSEDIA,
      process.env.GATSBY_FLEURY_BASE64_AUTH
    )

    accessTokenDataSource.getAccessToken()

    const data = this.props.data
    const metadata = data.cosmicjsHomeMedico.metadata
    const allCosmicjsRevistasMedicas = data.allCosmicjsRevistasMedicas.edges
    const medicalOptions = metadata.opcoes_medico

    const newsCards = data.allCosmicjsNoticias.edges.map(card => ({
      imgSrc: card.node.metadata.thumbnail.imgix_url,
      imgAlt: metadata.seo.imagemAlternativa,
      title: card.node.title,
      body: card.node.metadata.descricaoSimples,
      linkText: 'Leia mais',
      linkTo: getDetailPageUrl(appPaths.news.path.doctorUrl, card.node.slug),
      kind: CardKind.image
    })) as [CardData, CardData, CardData]

    const cosmicjsHomeMedico = data.cosmicjsHomeMedico.metadata
    const banners = cosmicjsHomeMedico.carrosselBanners.map(item => ({
      image: item.imagem.imgix_url,
      image_mobile: item.imagem_mobile.imgix_url,
      link: item.link
    }))

    const carouselProps: FeaturedCarouselProps = {
      items: metadata.destaquesCarrossel.map(item => ({
        area: item.assunto,
        title: item.titulo,
        description: item.descricao,
        link: item.link,
        image: {
          url: item.imagem.imgix_url,
          alt: item.imagemAlternativa
        }
      }))
    }

    return (
      <IndexLayout location={this.props.location}>
        <SEO
          socialMedia={{
            canonicalUrl: `${this.props.data.site.siteMetadata.siteUrl}${this.props.location.pathname}${this.props.location.search}`,
            title: this.props.data.cosmicjsHomeMedico.metadata.seo.titulo,
            image: this.props.data.cosmicjsHomeMedico.metadata.seo.imagem.url,
            imageAlt: this.props.data.cosmicjsHomeMedico.metadata.seo.imagemAlternativa,
            description: this.props.data.cosmicjsHomeMedico.metadata.seo.descricao
          }}
        />

        {process.env.GATSBY_COSMIC_BUCKET.toLocaleLowerCase() !== 'weinmann' && (
          <QuickAccess isDoctor={true} options={doctorQuickAccess} />
        )}

        {process.env.GATSBY_COSMIC_BUCKET.toLocaleLowerCase() !== 'weinmann' ? (
          <MatchMedia defaultMinWidth={headerBreakpoint}>
            {md =>
              !md && (
                <NeutralBackground>
                  <BannerCarousel banners={banners} />
                </NeutralBackground>
              )
            }
          </MatchMedia>
        ) : (
          <ContainerBannerWeinmann>
            <BannerCarouselNew banners={banners} />
          </ContainerBannerWeinmann>
        )}

        {process.env.GATSBY_COSMIC_BUCKET.toLocaleLowerCase() !== 'weinmann' && (
          <CardsSection
            metadata={{
              sectionTitle: metadata.noticiasAssunto,
              displayText: metadata.noticiasTitulo,
              buttonText: 'Ver todas notícias',
              buttonLinkto: appPaths.news.path.doctorUrl
            }}
            cardsData={[newsCards[0], newsCards[1], newsCards[2]]}
            id="doctor-news"
          />
        )}

        {process.env.GATSBY_COSMIC_BUCKET.toLocaleLowerCase() === 'weinmann' && (
          <>
            <ContainerCardsWeinmann>
              <CardsSectionDoctor options={medicalOptions} />
            </ContainerCardsWeinmann>

            <CardsSectionMedicalMagazine magazines={allCosmicjsRevistasMedicas} />
            <FeaturedCarouselNew {...carouselProps} />
          </>
        )}

        <DoctorMiddleSectionComponent {...this.props.pageContext} />

        {process.env.GATSBY_COSMIC_BUCKET.toLocaleLowerCase() !== 'weinmann' && (
          <FeaturedCarousel {...carouselProps} />
        )}
        <DoctorLastSectionComponent {...this.props.pageContext} />
      </IndexLayout>
    )
  }
}

export default HomeDoctor

export const query =
  process.env.GATSBY_COSMIC_BUCKET.toLocaleLowerCase() === 'weinmann'
    ? graphql`
        query HomeDoctorDetailWeinmann {
          site {
            siteMetadata {
              siteUrl
            }
          }

          cosmicjsHomeMedico(slug: { eq: "home-medico" }) {
            id
            title
            content
            metadata {
              noticiasAssunto
              noticiasTitulo

              destaquesCarrossel {
                assunto
                titulo
                descricao
                link
                imagemAlternativa
                imagem {
                  url
                  imgix_url
                }
              }

              carrosselBanners {
                link
                imagem {
                  url
                  imgix_url
                }
                imagem_mobile {
                  url
                  imgix_url
                }
              }

              opcoes_medico {
                titulo
                descricao
                link_botao
                texto_botao
                image_opcao {
                  url
                  imgix_url
                }
              }

              seo {
                titulo
                imagemAlternativa
                imagem {
                  url
                }
                descricao
              }
            }
          }

          cosmicjsInformacoesEstaticas(slug: { eq: "informacoes-de-contato" }) {
            metadata {
              telefoneContatoMedico
              chatOnlineLinkMedico
              agendamentoWhatsappLinkMedico
            }
          }

          allCosmicjsRevistasMedicas: allCosmicjsRevistasMedicas {
            edges {
              node {
                id
                title
                content
                published_at
                metadata {
                  descricao
                  edicao
                  pdf {
                    url
                  }
                  imagemCapa {
                    url
                    imgix_url
                  }
                }
              }
            }
          }

          allCosmicjsNoticias(sort: { order: DESC, fields: [metadata___noticiaData] }, limit: 3) {
            edges {
              node {
                title
                slug
                type_slug
                metadata {
                  descricaoSimples
                  thumbnail {
                    url
                    imgix_url
                  }
                }
              }
            }
          }
        }
      `
    : graphql`
        query HomeDoctorDetail {
          site {
            siteMetadata {
              siteUrl
            }
          }

          cosmicjsHomeMedico(slug: { eq: "home-medico" }) {
            id
            title
            content
            metadata {
              noticiasAssunto
              noticiasTitulo

              destaquesCarrossel {
                assunto
                titulo
                descricao
                link
                imagemAlternativa
                imagem {
                  url
                  imgix_url
                }
              }

              carrosselBanners {
                link
                imagem {
                  url
                  imgix_url
                }
                imagem_mobile {
                  url
                  imgix_url
                }
              }

              seo {
                titulo
                imagemAlternativa
                imagem {
                  url
                }
                descricao
              }
            }
          }

          cosmicjsInformacoesEstaticas(slug: { eq: "informacoes-de-contato" }) {
            metadata {
              telefoneContatoMedico
              chatOnlineLinkMedico
              agendamentoWhatsappLinkMedico
            }
          }

          allCosmicjsNoticias(sort: { order: DESC, fields: [metadata___noticiaData] }, limit: 3) {
            edges {
              node {
                title
                slug
                type_slug
                metadata {
                  descricaoSimples
                  thumbnail {
                    url
                    imgix_url
                  }
                }
              }
            }
          }
        }
      `
