import * as React from 'react'

import { Next, Prev, Wrapper } from './styles'

import { useMediaQuery, useTheme } from '@material-ui/core'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader
import { FaIcon } from 'atomic'

export const NewCarousel = ({ children }) => {
  const theme = useTheme()
  const downOfSm = useMediaQuery(theme.breakpoints.down('xs'))

  return (
    <>
      <Wrapper>
        <Carousel
          interval={5000}
          stopOnHover={false}
          showArrows={downOfSm ? false : true}
          showThumbs={false}
          showStatus={false}
          showIndicators={true}
          swipeable={true}
          infiniteLoop={true}
          autoPlay={true}
          renderArrowPrev={(onClickHandler, hasPrev, label) =>
            hasPrev && (
              <Prev type="button" onClick={onClickHandler} title={label}>
                <FaIcon.ChevronLeft />
              </Prev>
            )
          }
          renderArrowNext={(onClickHandler, hasNext, label) =>
            hasNext && (
              <Next type="button" onClick={onClickHandler} title={label}>
                <FaIcon.ChevronRight />
              </Next>
            )
          }
        >
          {children}
        </Carousel>
      </Wrapper>
    </>
  )
}
