import React from 'react'

const SvgIcManualDeExames = props => (
  <svg viewBox="0 0 32 32" {...props}>
    <defs>
      <path
        d="M3 25v-2H2a1 1 0 010-2h1v-2H2a1 1 0 010-2h1v-2H2a1 1 0 010-2h1v-2H2a1 1 0 010-2h1V7H2a1 1 0 110-2h1V3a3 3 0 013-3h20a3 3 0 013 3v26a3 3 0 01-3 3H6a3 3 0 01-3-3v-2H2a1 1 0 010-2h1zm2 0h1a1 1 0 010 2H5v2a1 1 0 001 1h20a1 1 0 001-1V3a1 1 0 00-1-1H6a1 1 0 00-1 1v2h1a1 1 0 110 2H5v2h1a1 1 0 110 2H5v2h1a1 1 0 010 2H5v2h1a1 1 0 010 2H5v2h1a1 1 0 010 2H5v2zm14-12h1.5a1.5 1.5 0 011.5 1.5v3a1.5 1.5 0 01-1.5 1.5H19v1.5a1.5 1.5 0 01-1.5 1.5h-3a1.5 1.5 0 01-1.5-1.5V19h-1.5a1.5 1.5 0 01-1.5-1.5v-3a1.5 1.5 0 011.5-1.5H13v-1.5a1.5 1.5 0 011.5-1.5h3a1.5 1.5 0 011.5 1.5V13zm1 2h-2a1 1 0 01-1-1v-2h-2v2a1 1 0 01-1 1h-2v2h2a1 1 0 011 1v2h2v-2a1 1 0 011-1h2v-2z"
        id="ic-manual-de-exames_svg__a"
      />
    </defs>
    <g fillRule="evenodd">
      <mask id="ic-manual-de-exames_svg__b" fill="#fff">
        <use xlinkHref="#ic-manual-de-exames_svg__a" />
      </mask>
      <use fill={props.fill} fillRule="nonzero" xlinkHref="#ic-manual-de-exames_svg__a" />
    </g>
  </svg>
)

export default SvgIcManualDeExames
