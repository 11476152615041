import { Body, Button, Col, Grid, H2, HDisplay, Row, Separator } from 'atomic'
import { MatchMedia } from 'atomic/legacy/obj.match-media'
import { HorizontalScroll } from 'atomic/obj.horizontal-scroll'
import * as React from 'react'
import LargeSeparatorRow from '../../../../atomic/atm.large-separator-row/large-separator-row.component'
import { NeutralBackground } from '../atm.background/background.styled'
import { Card, CardKind } from '../mol.card/card.component'
import { CardsRow, CardsSectionStyled, CardWrapper } from './cards-section.component.style'

export interface CardData {
  imgSrc: string
  imgAlt: string
  title: string
  body: string
  linkText: string
  linkTo: string
  kind: CardKind
}

export interface CardsSectionMetadataProps {
  sectionTitle: string
  sectionDescription?: string
  displayText: string
  buttonText: string
  buttonLinkto: string
}

interface CardsSectionProps {
  metadata: CardsSectionMetadataProps
  cardsData: CardData[]
  id: string
}

export const CardsSection: React.FunctionComponent<CardsSectionProps> = ({ id, ...props }) => {
  const sectionData = props.metadata
  const cards = props.cardsData.slice(0, 3)
  const firstCardOffset = getFirstCardOffset(cards.length)
  const cardSize = getCardColSize(cards.length)
  const cardsElement = (
    <>
      {cards.map(
        (cardData, index) =>
          cardData &&
          cardData.imgSrc && (
            <Col xs={12} mdOffset={index === 0 ? firstCardOffset : 0} md={cardSize} key={index}>
              <CardWrapper>
                <Card
                  imgSrc={cardData.imgSrc}
                  imgAlt={cardData.imgAlt}
                  title={cardData.title}
                  body={cardData.body}
                  linkText={cardData.linkText}
                  linkTo={cardData.linkTo}
                  kind={cardData.kind}
                  id={`${id}-${index}`}
                />
              </CardWrapper>
            </Col>
          )
      )}
    </>
  )
  return (
    <CardsSectionStyled>
      <NeutralBackground>
        <Grid>
          <LargeSeparatorRow />
          <CardsRow noGutter>
            <Col xs={12} sm={8} md={3} lg={3} gutter>
              <Row>
                <Col xs={12}>
                  <H2>{sectionData.sectionTitle}</H2>
                  <HDisplay>{sectionData.displayText}</HDisplay>
                  {sectionData.sectionDescription && <Body>{sectionData.sectionDescription}</Body>}
                </Col>
                <Col xs={12} sm={6} md={12} lg={9}>
                  <Separator />
                  <Button
                    id={`button-section-card-${id}`}
                    to={sectionData.buttonLinkto}
                    kind={'secondary'}
                    expanded
                  >
                    {sectionData.buttonText}
                  </Button>
                  <Separator />
                </Col>
              </Row>
            </Col>
            <MatchMedia defaultMinWidth={`64em`}>
              {md => (
                <>
                  {md && <HorizontalScroll>{cardsElement}</HorizontalScroll>}
                  {!md && <>{cardsElement}</>}
                </>
              )}
            </MatchMedia>
          </CardsRow>
          <LargeSeparatorRow />
        </Grid>
      </NeutralBackground>
    </CardsSectionStyled>
  )
}

const getCardColSize = numberOfCards => {
  switch (numberOfCards) {
    case 1:
      return 4

    case 2:
      return 4

    default:
      return 3
  }
}

const getFirstCardOffset = numberOfCards => {
  switch (numberOfCards) {
    case 1:
      return 2

    case 2:
      return 1

    default:
      return 0
  }
}
