import * as React from 'react'
import Title from '../Title'
import { Wrapper, Image, Description } from './org.card.style'
import { Button } from 'atomic'

export const CardSectionDoctor = ({ title, img, description, buttonLink, buttonDescription }) => {
  return (
    <Wrapper>
      {process.env.GATSBY_COSMIC_BUCKET.toLocaleLowerCase() === 'weinmann' ? (
        <Title className="newLayout">{title}</Title>
      ): (
        <Title variant="h5">{title}</Title>
      )}
      <Image src={img} />
      <Description>{description}</Description>
      <Button id={`button-section-card-doctor`} to={buttonLink} kind={'secondary'}>
        {buttonDescription}
      </Button>
    </Wrapper>
  )
}
