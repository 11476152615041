import styled from 'styled-components'

export const Wrapper = styled.div`
  margin-top: 32px;
  margin-bottom: 32px;
  overflow: hidden;
  color: #373a3a;

  @media(max-width:767px) {
    a {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 10px 0;
    }
  }

`
