import { AutoMargin, BodySecondary, Button, H3 } from 'atomic'
import { LazyLoadImage } from 'atomic/legacy/atm.lazy-load-image'
import { Link } from 'gatsby'
import * as React from 'react'
import {
  CardLinkWrapperStyled,
  CardStyled,
  CardTextboxBodyWrapperStyled,
  CardTextboxTitleStyled,
  ImgWrapperStyled
} from './card.component.style'

export enum CardKind {
  image = 'image',
  icon = 'icon'
}

export interface CardProps {
  linkText: string
  linkTo: string
  imgSrc: string
  imgAlt: string
  title: string
  body?: string
  kind: CardKind
  id?: string
}

export const Card: React.FunctionComponent<CardProps> = React.memo(
  ({ id, linkTo, imgSrc, kind, imgAlt, title, body, linkText }) => {
    const idProp: any = {}
    if (id) idProp.id = `card-id-${id}`
    return (
      <Link to={linkTo} {...idProp}>
        <CardStyled>
          <ImgWrapperStyled kind={kind}>
            <LazyLoadImage
              src={imgSrc}
              sizes={'25vw'}
              fitWidth
              aspectRatio={getAspectRatio(kind)}
              alt={imgAlt}
            />
          </ImgWrapperStyled>
          <CardTextboxTitleStyled>
            <H3>{title}</H3>
          </CardTextboxTitleStyled>
          {body && (
            <CardTextboxBodyWrapperStyled>
              <BodySecondary>{body}</BodySecondary>
            </CardTextboxBodyWrapperStyled>
          )}
          <AutoMargin />
          <CardLinkWrapperStyled>
            <Button id="button-card-component" kind="link">
              {linkText}
            </Button>
          </CardLinkWrapperStyled>
        </CardStyled>
      </Link>
    )
  }
)

const getAspectRatio = (kind: CardKind) => {
  switch (kind) {
    case 'icon': {
      return 1
    }
    case 'image': {
      return 0.67
    }
    default:
      console.error('Card:getAspectRatio: invalid kind: ', kind)
      return null
  }
}
