import { H4, Spacing } from 'atomic'
import { highlightStyle } from 'atomic/legacy/obj.mixin'
import ColorFunc from 'color'
import styled from 'styled-components'

export interface VerticalIconTitleStyledProps {
  /** color hex to be used on :hover and :active */
  actionColor: string
}

export const VerticalIconTitleStyled = styled.div<VerticalIconTitleStyledProps>`
  ${highlightStyle}
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  text-align: center;
  transition: all 0.2s ease-in-out;

  padding: ${Spacing.Small} 0px;
  @media all and (min-width: 75em) {
    padding: ${Spacing.Small};
  }
  svg {
    fill: ${props => props.actionColor};
  }

  &:hover {
    color: ${props => props.actionColor};
    svg {
      transition: all 0.2s ease-in-out;
      fill: ${props =>
        ColorFunc(props.actionColor)
          .darken(0.2)
          .hsl()
          .string()};
      color: ${props =>
        ColorFunc(props.actionColor)
          .darken(0.2)
          .hsl()
          .string()};
    }

    ${H4} {
      transition: all 0.2s ease-in-out;
      color: ${props =>
        ColorFunc(props.actionColor)
          .darken(0.2)
          .hsl()
          .string()};
    }
  }

  &:active {
    color: ${props => props.actionColor};
    svg {
      fill: ${props =>
        ColorFunc(props.actionColor)
          .darken(0.4)
          .hsl()
          .string()};
      color: ${props =>
        ColorFunc(props.actionColor)
          .darken(0.4)
          .hsl()
          .string()};
    }

    ${H4} {
      color: ${props =>
        ColorFunc(props.actionColor)
          .darken(0.4)
          .hsl()
          .string()};
    }
  }

  & > :first-child {
    margin-bottom: ${Spacing.Small};
  }
`
