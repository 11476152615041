import * as React from 'react'

import { useMediaQuery, useTheme } from '@material-ui/core'

import { NewCarousel } from '../Carousel'
import { Link } from './banner-carousel.component-new.style'

interface BannerCarouselProps {
  banners: {
    image: string
    image_mobile?: string
    link?: string
  }[]
}

export const BannerCarouselNew: React.FunctionComponent<BannerCarouselProps> = props => {
  const theme = useTheme()

  const downOfSm = useMediaQuery(theme.breakpoints.down('xs'))

  const downOfMd = useMediaQuery(theme.breakpoints.down('sm'))
  const adjustBannerMargin = downOfMd ? 110 : 0

  React.useEffect(() => console.log(downOfSm), [downOfSm])
  return (
    <>
      <NewCarousel>
        {props.banners.map(banner =>
          banner.link ? (
            <Link target="_blank" href={banner.link}>
              <img src={downOfSm ? banner.image_mobile : banner.image} style={{marginTop: adjustBannerMargin}} />
            </Link>
          ) : (
            <>
              <img src={downOfSm ? banner.image_mobile : banner.image} style={{marginTop: adjustBannerMargin}} />
            </>
          )
        )}
      </NewCarousel>
    </>
  )
}
