import React from 'react'

const SvgIcConvenios = props => (
  <svg viewBox="0 0 32 32" {...props}>
    <defs>
      <path
        d="M3 3h26a3 3 0 013 3v20a3 3 0 01-3 3H3a3 3 0 01-3-3V6a3 3 0 013-3zm0 2a1 1 0 00-1 1v20a1 1 0 001 1h26a1 1 0 001-1V6a1 1 0 00-1-1H3zm9 3.5v6a1.5 1.5 0 01-1.5 1.5h-5A1.5 1.5 0 014 14.5v-6A1.5 1.5 0 015.5 7h5A1.5 1.5 0 0112 8.5zM10 9H6v5h4V9zm5 0a1 1 0 010-2h12.001a1 1 0 010 2H15zm0 4a1 1 0 010-2h5.999a1 1 0 010 2H15zm1 10h10a1 1 0 010 2H5a1 1 0 010-2h11z"
        id="ic-convenios_svg__a"
      />
    </defs>
    <g fillRule="evenodd">
      <mask id="ic-convenios_svg__b" fill="#fff">
        <use xlinkHref="#ic-convenios_svg__a" />
      </mask>
      <use fill={props.fill} fillRule="nonzero" xlinkHref="#ic-convenios_svg__a" />
      <g mask="url(#ic-convenios_svg__b)" fill={props.fill}>
        <path d="M0 0h32v32H0z" />
      </g>
    </g>
  </svg>
)

export default SvgIcConvenios
