import * as React from 'react'
import { Carousel, CarouselPager } from '../legacy/atm.carousel'
import { LazyLoadImage } from '../legacy/atm.lazy-load-image'

interface BannerCarouselProps {
  banners: {
    image: string
    link?: string
  }[]
}

export const BannerCarousel: React.FunctionComponent<BannerCarouselProps> = props => {
  const [index, setIndex] = React.useState(0)
  const handleIndexChange = selectedIndex => setIndex(selectedIndex)
  return (
    <>
      <Carousel
        loop={props.banners.length > 2}
        auto={props.banners.length > 2}
        showNavigationButton={props.banners.length > 1}
        interval={5000}
        width={1}
        scale={1}
        disablePan={true}
        borderItemsXTranslation={0}
        onIndexChanged={handleIndexChange}
        id="banner-carousel"
      >
        {props.banners.map((item, i) =>
          item.link ? (
            // tslint:disable-next-line: react-a11y-anchors
            <a href={item.link} key={i}>
              <LazyLoadImage
                src={item.image}
                aspectRatio={360 / 1440}
                alt={`bannerimage-${i}`}
                sizes={'100vw'}
              />
            </a>
          ) : (
            <LazyLoadImage
              key={i}
              src={item.image}
              aspectRatio={360 / 1440}
              alt={`bannerimage-${i}`}
              sizes={'100vw'}
            />
          )
        )}
      </Carousel>
      <CarouselPager total={props.banners.length} current={index} />
    </>
  )
}
