import {
  CarrouselItem,
  CarrouselItemImageHeight
} from '@root/src/components/mol.carrousel-item/carrousel-item.component'
import { Carousel, CarouselPager } from 'atomic/legacy/atm.carousel'
import { ForcedFade } from 'atomic/legacy/obj.animation/animation.component.style'
import { MatchMedia } from 'atomic/legacy/obj.match-media'
import * as React from 'react'
import { buildURL } from 'react-imgix'
import Observer from 'react-intersection-observer'
import {
  FeaturedCarouselBackgroundStyled,
  FeaturedCarouselBackgroundWrapperStyled,
  FeaturedCarouselPageControllWrapperStyled,
  FeaturedCarouselStyled
} from './featured-carousel.component.style'

export interface FeaturedCarouselProps {
  items: {
    area: string
    title: string
    description: string
    link?: string
    image: {
      url: string
      alt: string
    }
  }[]
}

interface BackgroundImageProps {
  imgixUrl: string
}

const BackgroundImage: React.FunctionComponent<BackgroundImageProps> = props => {
  const url = buildURL(props.imgixUrl, { h: CarrouselItemImageHeight })

  return (
    <FeaturedCarouselBackgroundWrapperStyled>
      <Observer triggerOnce={true}>
        {({ inView, ref }) => (
          <ForcedFade show={true} key={url}>
            <FeaturedCarouselBackgroundStyled ref={ref} inView={inView} url={url} />
          </ForcedFade>
        )}
      </Observer>
      {props.children}
    </FeaturedCarouselBackgroundWrapperStyled>
  )
}

export const FeaturedCarousel: React.FunctionComponent<FeaturedCarouselProps> = props => {
  const [index, setIndex] = React.useState(0)
  const handleIndexChange = selectedIndex => setIndex(selectedIndex)

  return (
    <BackgroundImage imgixUrl={props.items[index].image.url}>
      <MatchMedia defaultMinWidth={`48rem`}>
        {md => (
          <FeaturedCarouselStyled>
            <Carousel
              loop={true}
              showNavigationButton={props.items.length > 1}
              interval={3000}
              width={md ? 0.75 : 0.5}
              scale={md ? 1 : 0.7}
              additionalArrowTranslationY={md ? 190 : 0}
              borderItemsXTranslation={md ? 0 : 200}
              onIndexChanged={handleIndexChange}
              id="featured-carousel"
            >
              {props.items.map((item, index) => (
                <CarrouselItem
                  key={item.title}
                  title={item.area}
                  textDisplay={item.title}
                  description={item.description}
                  linkTo={item.link}
                  buttonText="Saber mais"
                  imgSrc={item.image.url}
                  imgAlt={item.image.alt}
                  id={`carrousel-item-${index}`}
                />
              ))}
            </Carousel>
            <FeaturedCarouselPageControllWrapperStyled>
              <CarouselPager total={props.items.length} current={index} light />
            </FeaturedCarouselPageControllWrapperStyled>
          </FeaturedCarouselStyled>
        )}
      </MatchMedia>
    </BackgroundImage>
  )
}
