import { Border } from 'atomic'
import styled from 'styled-components'

export const CarrouselItemStyled = styled.div`
  position: relative;
  border-radius: ${Border.Radius};
  overflow: hidden;

  .font{
    font-size:28px!important;
  }
`

export const ImgWrapperStyled = styled.div`
  border-radius: ${Border.Radius};
  overflow: hidden;
  align-items: center;
  height: '340px';
  width: '340px';
`
