import React from 'react'

const SvgIcInstrucoesResultados = props => (
  <svg viewBox="0 0 32 32" width={32} height={32} {...props}>
    <defs>
      <path
        d="M21.015.74l.08.076 7.09 7.09c.045.044.09.09.135.14l.682.681v20.364c0 1.607-1.123 2.909-2.73 2.909H5.91A2.91 2.91 0 013 29.09V2.91A2.91 2.91 0 015.91 0h14.365l.74.74zM27 10.545h-5.272a3.092 3.092 0 01-3.092-3.092V2L5.73 2.002a.727.727 0 00-.727.727v26.362c0 .402.325.727.727.727h20.544a.727.727 0 00.727-.727V10.545zM25.88 8.49l-5.23-5.33v4.293c0 .427.387 1.037.814 1.037h4.417zM11.365 9.818v.727a1.09 1.09 0 01-2.182 0v-.727h-.727a1.09 1.09 0 010-2.182h.727V6.91a1.09 1.09 0 012.182 0v.727h.727a1.09 1.09 0 010 2.182h-.727zm-3 8.182a1 1 0 010-2h15.675a1 1 0 010 2H8.364zm0 4.364a1 1 0 010-2H24.02a1 1 0 110 2H8.364zm0 4.363a1 1 0 110-2h15.604a1 1 0 110 2H8.364z"
        id="ic-instrucoes-resultados_svg__a"
      />
    </defs>
    <g fillRule="evenodd">
      <mask id="ic-instrucoes-resultados_svg__b" fill="#fff">
        <use xlinkHref="#ic-instrucoes-resultados_svg__a" />
      </mask>
      <use fill={props.fill} fillRule="nonzero" xlinkHref="#ic-instrucoes-resultados_svg__a" />
    </g>
  </svg>
)

export default SvgIcInstrucoesResultados
