// tslint:disable:only-arrow-functions
// tslint:disable:no-this-assignment
// tslint:disable:one-variable-per-declaration

export const defaultDebounceTime = 800

/** adapted from: https://davidwalsh.name/javascript-debounce-function */
export function asyncDebounce(asynFunc, wait: number = defaultDebounceTime, immediate?: boolean) {
  let timeout

  return function() {
    const context = this,
      args = arguments

    return new Promise(resolve => {
      const later = function() {
        timeout = null
        if (!immediate) {
          resolve(asynFunc.apply(context, args))
        }
      }
      const callNow = immediate && !timeout
      clearTimeout(timeout)
      timeout = setTimeout(later, wait)
      if (callNow) {
        resolve(asynFunc.apply(context, args))
      }
    })
  }
}

export function debounce(func, wait = defaultDebounceTime, immediate?: boolean) {
  let timeout
  return function(this: any, ...args: any[]) {
    const context = this
    const later = function() {
      timeout = null
      if (!immediate) {
        func.apply(context, args)
      }
    }
    const callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
    if (callNow) {
      func.apply(context, args)
    }
  }
}

/**
 *
 * @param limit after calling `limit` times, the function will be called
 */
export function debounceWithLimit(func, limit: number, wait = defaultDebounceTime) {
  let timeout
  let count = 0

  return function() {
    const context = this,
      args = arguments
    const later = function() {
      timeout = null
      func.apply(context, args)
      count = 0
    }
    clearTimeout(timeout)

    if (count > limit) {
      func.apply(context, args)
      count = 0
    } else {
      timeout = setTimeout(later, wait)
      count += 1
    }
  }
}
