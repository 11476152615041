import { Body, Button, Col, H3, HDisplay, Row, Separator } from 'atomic'
import { LazyLoadImage } from 'atomic/legacy/atm.lazy-load-image'
import * as React from 'react'
import { CarrouselItemStyled, ImgWrapperStyled } from './carrousel-item.component.style'
import { isExternalUrl } from 'utils/url'

interface CarrouselItemProps {
  title: string
  textDisplay: string
  description: string
  linkTo: string
  buttonText?: string
  imgSrc: string
  imgAlt: string
  id: string
}

export const CarrouselItemImageHeight = '350px'

export const CarrouselItem: React.FunctionComponent<CarrouselItemProps> = ({ id, ...props }) => {
  return (
    <CarrouselItemStyled id={id}>
      <Row>
        <Col xs={12} sm={12} md={6} lg={6}>
          <H3 kind="light">{props.title}</H3>
          <HDisplay className="font" kind="light">{props.textDisplay}</HDisplay>
          <Body kind="light">{props.description}</Body>
          <Separator />
          <Button
            id={`button-${id}`}
            kind="secondary"
            light
            to={props.linkTo}
            external={isExternalUrl(props.linkTo)}
          >
            {props.buttonText}
          </Button>
          <Separator />
        </Col>
        <Col xs={12} sm={12} md={6} lg={6}>
          <ImgWrapperStyled>
            <LazyLoadImage src={props.imgSrc} alt={props.imgAlt} aspectRatio={1} fitWidth />
          </ImgWrapperStyled>
        </Col>
      </Row>
    </CarrouselItemStyled>
  )
}
