import { Color } from 'atomic'
import styled from 'styled-components'
import { headerBreakpoint } from '../legacy/org.header/header.component.style'

export const QuickAccessStyled = styled.div`
  background-color: ${Color.Neutral};
  /* margin-top: 128px; */
  @media all and (min-width: ${headerBreakpoint}) {
    display: none;
  }
`
