import styled from 'styled-components'

export const Wrapper = styled.div`
  overflow: hidden;
  .control-dots {
    position: initial !important;

    li {
      width: 10px !important;
      height: 10px !important;
      box-shadow: none !important;
      border: 3px solid ${props => props.theme.color.primary} !important;
      opacity: 1 !important;

      &.selected {
        background-color: ${props => props.theme.color.primary};
      }
    }
  }
`

export const Prev = styled.button`
  background-color: #018391;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  color: #fff;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  position: absolute;
  top: 40%;
  padding: 15px;
  z-index: 2;
  font-size: 16px;
  margin-left: 10px;

  :hover {
    opacity: 80%;
  }
`

export const Next = styled.button`
  background-color: #018391;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  color: #fff;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  position: absolute;
  top: 40%;
  z-index: 2;
  right: 0px;
  font-size: 16px;
  margin-right: 10px;

  :hover {
    opacity: 80%;
  }
`
