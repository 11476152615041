import styled from 'styled-components'

export const Wrapper = styled.div`
  font-family: Signika;
  margin-top: 32px;
  margin-bottom: 32px;
  overflow: hidden;
  color: #373a3a;

  .carousel .slide {
    background-color: white !important;
  }

  .control-dots {
    margin-top: 15px !important;
    position: initial !important;
    li {
      width: 10px !important;
      height: 10px !important;
      box-shadow: none !important;
      border: 1px solid ${props => props.theme.color.primary};
      opacity: 1 !important;

      &.selected {
        background-color: ${props => props.theme.color.primary};
      }
    }
  }
`

export const CarouselItem = styled.div`
  margin: 15px;
  display: flex;
  flex-wrap: wrap;

  img {
    width: 177px;
    height: 204px;
    object-fit: cover;
  }
`

export const MagazineName = styled.span`
  margin: 5px 0px;
  display: flex;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-decoration-line: underline;
  width: 100%;
  color: ${props => props.theme.color.primary};
`

export const MagazineDate = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #666666;
`
export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;

  @media (max-width:767px) {
    a {
      width:100%;
      display: flex;
      justify-content: center;
    }
  }

`
