import * as React from 'react'
import { CarouselPagerBulletStyled, CarouselPagerStyled } from './carousel-pager.component.style'

interface CarouselPagerProps {
  total: number
  current: number
  light?: boolean
}

export const CarouselPager = (props: CarouselPagerProps) => {
  return (
    <CarouselPagerStyled>
      {Array.apply(0, Array(props.total)).map((_val: number, index: number) => (
        <CarouselPagerBulletStyled key={index} active={index === props.current} />
      ))}
    </CarouselPagerStyled>
  )
}
