import styled from 'styled-components'

export const ContainerBannerWeinmann = styled.div`
  @media (min-width: 1024px) {
    display: flex;

  }
`
export const ContainerCardsWeinmann = styled.div`
  @media (min-width:1023px) {
    margin-top:20px
  }

  >div{
    overflow:unset;
  }
`
