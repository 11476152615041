import styled from 'styled-components'
import { Spacing } from 'atomic/legacy/obj.constants'

const blackBackground = 'background-color: rgba(0, 0, 0, 0.8);'

export const FeaturedCarouselStyled = styled.div`
  position: relative;
  padding-top: ${Spacing.XXLarge};
  padding-bottom: ${Spacing.Large};

  :before {
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    width: 100%;
    height: 100%;
    ${blackBackground}
  }
`

export const FeaturedCarouselBackgroundWrapperStyled = styled.div`
  position: relative;
  overflow: hidden;
  ${blackBackground}
`

export const FeaturedCarouselBackgroundStyled = styled.div<{ inView: boolean; url: string }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  ${blackBackground}
  filter: blur(4px);
  background-image: url(${props => (props.inView ? props.url : '')});
`

export const FeaturedCarouselPageControllWrapperStyled = styled.div`
  position: relative;
  margin-top: ${Spacing.Large};
`
