import SvgIcConvenios from 'atomic/atm.svg-icon/ic-convenios'
import SvgIcCorpoClinico from 'atomic/atm.svg-icon/ic-corpo-clinico'
import SvgIcDoctorRegister from 'atomic/atm.svg-icon/ic-doctor-register'
import SvgIcExames from 'atomic/atm.svg-icon/ic-exames'
import SvgIcInstrucoesResultados from 'atomic/atm.svg-icon/ic-instrucoes-resultados'
import SvgIcManualDeExames from 'atomic/atm.svg-icon/ic-manual-de-exames'
import SvgIcAtendimentoDomiciliar from 'atomic/atm.svg-icon/ic-atendimento-domiciliar'
import SvgIcUnidades from 'atomic/atm.svg-icon/ic-unidades'
import * as React from 'react'
import { QuickAccessItem } from 'utils/model/quick-access'
import { appPaths, externalPaths } from 'utils/path'

export const doctorQuickAccess: QuickAccessItem[] = [
  {
    path: externalPaths.felippeMattosoExamResultsDoctor.path,
    icon: <SvgIcInstrucoesResultados height={24} />,
    title: 'Resultados de exames'
  },
  {
    path: appPaths.examManual.path.doctorUrl,
    icon: <SvgIcManualDeExames height={24} />,
    title: 'Manual de exames'
  },
  {
    path: appPaths.convenio.path.doctorUrl,
    icon: <SvgIcConvenios height={24} />,
    title: 'Convênios atendidos'
  },
  {
    path: appPaths.units.path.doctorUrl,
    icon: <SvgIcUnidades height={24} />,
    title: appPaths.units.name
  },
  {
    path: appPaths.doctorRegister.path.doctorUrl,
    icon: <SvgIcDoctorRegister height={24} />,
    title: appPaths.doctorRegister.name
  }
]

export const clientQuickAccess: QuickAccessItem[] = [
         {
           path: externalPaths.felippeMattosoExamResultsClient.path,
           icon: <SvgIcInstrucoesResultados height={24} />,
           title: 'Resultados de exames'
         },
         {
           path: appPaths.exams.path.clientUrl,
           icon: <SvgIcExames height={24} />,
           title: 'Exames e vacinas'
         },
         {
           path: appPaths.convenio.path.clientUrl,
           icon: <SvgIcConvenios height={24} />,
           title: 'Convênios atendidos'
         },
         {
           path: appPaths.units.path.clientUrl,
           icon: <SvgIcUnidades height={24} />,
           title: 'Nossas unidades'
         },
         {
           path: `${appPaths.news.path.clientUrl}/noticias/conheca-o-atendimento-domiciliar`,
           icon: <SvgIcAtendimentoDomiciliar height={24} />,
           title: 'Atend. domiciliar'
         }
       ]
