import React from 'react'

const SvgIcUnidades = props => (
  <svg viewBox="0 0 32 32" {...props}>
    <defs>
      <path
        d="M12.678 30.133L5.99 18.603A11.965 11.965 0 014 11.977C4 5.363 9.334 0 15.918 0 22.5 0 27.835 5.363 27.835 11.976c0 2.402-.706 4.7-2.008 6.655l-6.67 11.502a3.746 3.746 0 01-6.48 0zM7.742 17.552l6.703 11.556a1.703 1.703 0 002.945 0l6.703-11.556a9.992 9.992 0 001.699-5.576c0-5.487-4.422-9.933-9.874-9.933-5.453 0-9.875 4.446-9.875 9.933 0 1.995.585 3.9 1.665 5.522l.034.054zM16 15a4 4 0 110-8 4 4 0 010 8zm0-2a2 2 0 100-4 2 2 0 000 4z"
        id="ic-unidades_svg__a"
      />
    </defs>
    <g fillRule="evenodd">
      <mask id="ic-unidades_svg__b" fill="#fff">
        <use xlinkHref="#ic-unidades_svg__a" />
      </mask>
      <use fill={props.fill} fillRule="nonzero" xlinkHref="#ic-unidades_svg__a" />
    </g>
  </svg>
)

export default SvgIcUnidades
