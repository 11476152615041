import * as React from 'react'

import {
  CarouselItem,
  MagazineName,
  Wrapper,
  MagazineDate,
  ButtonContainer
} from './org.cards-section-medical-magazine.style'

import { Container, useMediaQuery, useTheme } from '@material-ui/core'
import Title from '../Title'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader
import { Button } from '@root/../atomic'
import { parseDateToString } from '@root/src/utils/date-parser/date-parser'

export const CardsSectionMedicalMagazine = ({ magazines }: any) => {
  const theme = useTheme()

  const downOfSm = useMediaQuery(theme.breakpoints.down('xs'))

  return (
    <Wrapper>
      <Container maxWidth="md">
        <Title className="newLayout">Revistas médicas</Title>
        <Carousel
          showArrows={false}
          showThumbs={false}
          showStatus={false}
          showIndicators={downOfSm ? true : false}
          swipeable={downOfSm ? true : false}
          centerMode
          centerSlidePercentage={downOfSm ? 60 : 25}
        >
          {magazines
            .sort((a, b) => b.node.metadata.edicao - a.node.metadata.edicao)
            .slice(0, 4)
            .map(magazine => (
              <a target="_blank" href={magazine.node.metadata.pdf.url}>
                <CarouselItem>
                  <img src={magazine.node.metadata.imagemCapa.imgix_url} />
                  <MagazineName>Edição {magazine.node.metadata.edicao}</MagazineName>
                  <MagazineDate>
                    {`${parseDateToString(new Date(magazine.node.published_at))}`}
                  </MagazineDate>
                </CarouselItem>
              </a>
            ))}
        </Carousel>
        <ButtonContainer>
          <Button
            id={`button-section-card-doctor`}
            to="/medico/revistas-medicas"
            kind={'secondary'}
          >
            Ver todas as edições
          </Button>
        </ButtonContainer>
      </Container>
    </Wrapper>
  )
}
