import React from 'react'

const SvgIcDoctorRegister = props => (
  <svg viewBox="0 0 32 32" {...props}>
    <defs>
      <path
        d="M25.34 10.975a6.635 6.635 0 1 1 0 13.27 6.635 6.635 0 0 1 0-13.27zM19 .43a1 1 0 0 1 .57.3l2.35 2.35h.22a6.27 6.27 0 0 1 6.27 6v1.22l-2-.4v-.56a4.3 4.3 0 0 0-4.3-4.3 1 1 0 0 1-.25 0L20.5 6.2V11a7.69 7.69 0 0 0-.95.85 8 8 0 0 0-1.03 1.39V7.9l-1 .87a1 1 0 0 1-1.35-.05l-.95-1v14.33a1 1 0 0 1-2 0V7.76l-1 1a1 1 0 0 1-1.35.05L9.21 7.38v6.42a3 3 0 0 1 2 2.6v1.53a1 1 0 0 1-2 0V16.6a1.002 1.002 0 0 0-2-.13v1.46a1 1 0 0 1-2 0V16.6a3 3 0 0 1 2-2.8V5.64l-.7-.6A4.29 4.29 0 0 0 2.34 9.1V22a1.002 1.002 0 0 1-2 .14V9.34a6.28 6.28 0 0 1 6.27-6.28h-.07L8.86.74a.92.92 0 0 1 .57-.31H19zm6.34 12.335a4.845 4.845 0 1 0 0 9.69 4.845 4.845 0 0 0 0-9.69zm.01.835a1 1 0 0 1 .993.883l.007.117-.001 2h2.001a1 1 0 0 1 .993.883l.007.117a1 1 0 0 1-1 1h-2.001l.001 2a1 1 0 0 1-1.993.117l-.007-.117-.001-2H22.35a1 1 0 0 1-.993-.883l-.007-.117a1 1 0 0 1 1-1h1.999l.001-2a1 1 0 0 1 1-1zM18.8 2.78l-3.12 2.67 1.24 1.23 1.77-1.52a1 1 0 0 1 .39-.33L20 4l-1.2-1.22zm-9.15 0L8.41 4l3.12 2.67 1.23-1.23-3.11-2.66zm6.52-.36h-3.89l1.94 1.67 1.95-1.67z"
        id="placeholder_cadastro_svg__a"
      />
    </defs>
    <g transform="translate(0 5)" fill={props.fill} fillRule="evenodd">
      <mask id="placeholder_cadastro_svg__b" fill="#fff">
        <use xlinkHref="#placeholder_cadastro_svg__a" />
      </mask>
      <use
        fillRule="nonzero"
        xlinkHref="#placeholder_cadastro_svg__a"
      />
      <g mask="url(#placeholder_cadastro_svg__b)" fill={props.fill}>
        <path d="M0-5h32v32H0z" />
      </g>
    </g>

  </svg>
)

export default SvgIcDoctorRegister
