import React from 'react'

const SvgIcExames = props => (
  <svg viewBox="0 0 32 32" {...props}>
    <defs>
      <path
        d="M24.365 16.512l-1.906-5.42-2.267 3.01a1 1 0 01-.799.398H17a1 1 0 010-2h1.895l3.09-4.102a1 1 0 011.741.27l1.876 5.333.74-1.07a1 1 0 01.823-.431h3.789a1 1 0 110 2h-3.265l-1.558 2.25a1 1 0 01-1.766-.238zM16 5.237A7.96 7.96 0 0123.031 1C27.433 1 31 4.588 31 9.01a1 1 0 01-2 0C29 5.69 26.326 3 23.031 3a5.96 5.96 0 00-5.266 3.178c-.753 1.415-2.777 1.415-3.53 0A5.96 5.96 0 008.969 3C5.674 3 3 5.69 3 9.01c0 1.342.436 2.622 1.244 3.674l2.615 3.628L16 28.999l6.589-9.145a1 1 0 111.623 1.169l-6.59 9.146a1.998 1.998 0 01-3.244 0L2.64 13.879A7.976 7.976 0 011 9.01C1 4.588 4.567 1 8.969 1A7.96 7.96 0 0116 5.237z"
        id="ic-exames_svg__a"
      />
    </defs>
    <g fillRule="evenodd">
      <mask id="ic-exames_svg__b" fill={props.fill}>
        <use xlinkHref="#ic-exames_svg__a" />
      </mask>
      <use fill={props.fill} fillRule="nonzero" xlinkHref="#ic-exames_svg__a" />
      <g mask="url(#ic-exames_svg__b)" fill={props.fill}>
        <path d="M0 0h32v32H0z" />
      </g>
    </g>
  </svg>
)

export default SvgIcExames
