import styled from 'styled-components'

export const Link = styled.a`
  display: flex;
  width: 100%;

  img {
    width: 100%;
    height: 100%;
  }
`
