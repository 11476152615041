import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'
import { Color } from '../..'
import { Spacing } from '../obj.constants'
import { highlightStyle } from '../obj.mixin/mixin.component.style'

interface CarouselStyledProps {
  showNavigationButton?: boolean
  disabled?: boolean
}

export const CarouselStyled = styled.div`
  width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  position: relative;
`

export const CarouselWrapperStyled = styled.div`
  overflow-x: hidden;
  overflow-y: hidden;
  ${(props: CarouselStyledProps) =>
    props.showNavigationButton &&
    `
    position: relative;
  `}
`

export const CarouselNavigationButtonStyled = styled(FontAwesomeIcon).attrs({
  icon: 'angle-down',
  size: '3x'
})<{ additionalpadding?; disabled?; additionalarrowtranslation?; onClick }>`
  box-sizing: content-box;
  position: absolute;
  padding: ${props =>
    (props.additionalpadding || 0) + parseInt(Spacing.XLarge, 0)}px;
  top: 50%;
  z-index: 80;
  ${props => !props.disabled && highlightStyle}
  color: ${props => (props.disabled ? Color.GrayLight : Color.White)};
  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.8));

  &:first-child {
    left: 0;
    transform: ${props =>
      `translateY(${(props.additionalarrowtranslation || 0) -
        50}%)`} rotate(90deg) scale(0.8);
    @media all and (min-width: 48em) {
      transform: ${props =>
        `translateY(${(props.additionalarrowtranslation || 0) -
          50}%)`} rotate(90deg);
    }
  }

  &:last-child {
    right: 0;
    transform: ${props =>
      `translateY(${(props.additionalarrowtranslation || 0) -
        50}%)`} rotate(-90deg) scale(0.8);
    @media all and (min-width: 48em) {
      transform: ${props =>
        `translateY(${(props.additionalarrowtranslation || 0) -
          50}%)`} rotate(-90deg);
    }
  }
`
